<template>
  <VDialog
    v-model="visibility"
    max-width="700px"
  >
    <VCard>
      <VRow class="ma-0 align-center justify-space-between npDialog__header">
        <VCol cols="12" md="6" class="npDialog__header-title">
          {{ `${typeDialog === 'add' ?  this.$t('profile.creating') : this.$t('profile.editing')}` }}
        </VCol>
      </VRow>
      <VCardText>
        <VContainer>
          <VRow class="my-0 py-0">
            <VCol cols="12">
              <VTextField dense :label="$t('form.name')" outlined
                          v-model="delivery.name"
                          :hide-details="!nameErrors.length"
                          :error-messages="nameErrors"
              />
            </VCol>
            <VCol cols="12">
              <VSelect dense :label="$t('form.counterparty')" outlined
                       :items="counterpartyData"
                       v-model="counterparty"
                       item-text="FirstName"
                       @change="getDataCounterpartyContacts"
                       :hide-details="!counterpartyErrors.length"
                       :error-messages="counterpartyErrors"
                       item-value="Ref"
              />
            </VCol>
            <VCol cols="12">
              <VSelect dense :label="$t('form.counterparty_sender')" outlined
                       :items="counterpartyContactsData"
                       v-model="counterpartyContacts"
                       :hide-details="!counterpartyContactsErrors.length"
                       :error-messages="counterpartyContactsErrors"
                       item-text="FirstName"
                       item-value="Ref"
              />
            </VCol>
            <VCol>
              <VAutocomplete
                :items="activeCities"
                :loading="regionLoading"
                outlined
                dense
                class="br-10"
                :label="$t('form.city_receiver')"
                item-text="Description"
                item-value="Ref"
                @change="onSelectCity"
                v-model="delivery.city"
                :hide-details="!cityErrors.length"
                :error-messages="cityErrors"
              />
            </VCol>
            <VCol>
              <VAutocomplete
                :items="warehouses"
                outlined
                dense
                class="br-10"
                :label="$t('form.department')"
                item-text="Description"
                item-value="Ref"
                v-model="delivery.warehouse"
                :hide-details="!warehouseErrors.length"
                :error-messages="warehouseErrors"
                :disabled="!delivery.city"
              />
            </VCol>
          </VRow>
        </VContainer>
      </VCardText>
      <VCardActions>
        <VContainer>
          <VRow class="my-0 py-0">
            <VCol cols="6" class="my-0 py-0">
              <VBtn
                class="w-100 br-10 text-transform-none"
                dark
                @click="visibility = false"
              >
                {{$t('btn.cancel')}}
              </VBtn>
            </VCol>
            <VCol cols="6" class="my-0 py-0">
              <VBtn
                class="success-bg text-white text-transform-none br-10 w-100"
                v-if="typeDialog === 'active'"
              >
                {{$t('btn.update')}}
              </VBtn>
              <VBtn
                class="success-bg text-white text-transform-none br-10 w-100"
                v-else
                @click="addSender"
              >
                {{ `${typeDialog === 'add' ?   $t('btn.create') :   $t('btn.update') }` }}
              </VBtn>
            </VCol>
          </VRow>
        </VContainer>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import user from '../../mixins/user';
import notifications from '../../mixins/notifications';
import loader from '../../mixins/loader';
import language from '../../mixins/language';
import novaPochtaService from '../../services/request/novaPochta/novaPochtaService';
import novaPochta from '../../mixins/novaPochta';
import EventBus from '../../events/EventBus';

export default {
  mixins: [notifications, loader, validationMixin, user, language, novaPochta],
  props: {
    typeDialog: {
      type: String,
    },
    visible: {
      required: true,
      type: Boolean,
    },
    data: {
      type: Object,
    },
  },
  data: () => ({
    counterpartyData: [],
    counterparty: null,
    counterpartyContactsData: [],
    counterpartyContacts: null,
    delivery: {
      uuid: '',
      city: '',
      city_name: '',
      name: '',
      warehouse: '',
      warehouse_name: '',
    },
  }),
  async mounted() {
    try {
      await this.fetchNovaPochtaCities();
      this.activeCities = this.cities;
      this.getDataCounterparty();
    } catch (e) {
      this.setErrorNotification(e);
    }
  },
  methods: {
    async getDataCounterparty() {
      try {
        this.loadingCreateTTN = true;
        const formData = {
          apiKey: this.getUser.np_apikey,
          modelName: 'Counterparty',
          calledMethod: 'getCounterparties',
          methodProperties: {
            CounterpartyProperty: 'Sender',
            Page: '1',
            FindByString: this.$t('profile.individual')
          },
        };
        const response = await novaPochtaService.postCreateTTN(formData);
        // eslint-disable-next-line prefer-destructuring
        this.counterpartyData = response.data.data;
        // orderDialog('this.counterpartyData', this.counterpartyData);
        this.loadingCreateTTN = false;
      } catch (e) {
        console.error(e);
        this.loadingCreateTTN = false;
      }
    },
    async getDataCounterpartyContacts() {
      try {
        this.loadingCreateTTN = true;
        const formData = {
          apiKey: this.getUser.np_apikey,
          modelName: 'Counterparty',
          calledMethod: 'getCounterpartyContactPersons',
          methodProperties: {
            Ref: this.counterparty,
            Page: '1',
          },
        };
        const response = await novaPochtaService.postCreateTTN(formData);
        // eslint-disable-next-line prefer-destructuring
        this.counterpartyContactsData = response.data.data;
        // orderDialog('this.counterpartyContactsData', this.counterpartyContactsData);
        this.loadingCreateTTN = false;
      } catch (e) {
        console.error(e);
        this.loadingCreateTTN = false;
      }
    },
    async addSender() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          const formData = new FormData();
          formData.append('name', this.delivery.name);
          formData.append('uuid_ref', this.counterparty);
          formData.append('uuid_ref_sender', this.counterpartyContacts);
          formData.append('uuid_ref_city', this.delivery.city);
          formData.append('uuid_ref_warehouse', this.delivery.warehouse);
          if (this.typeDialog === 'edit') {
            formData.append('uuid', this.delivery.uuid);
            await novaPochtaService.updateCounterpartyItem(formData);
          } else {
            formData.append('uuid_user', this.getUser.uuid);
            await novaPochtaService.addCounterpartyItem(formData);
          }
          // orderDialog('addCounterpartyItem', response);
          this.resetForm();
          this.$emit('close');
          EventBus.$emit('reload-list-counterparty');
        } catch (e) {
          console.error(e);
        }
      }
    },
    async pasteForm() {

      this.counterparty = this.data.uuid_ref;
      await this.getDataCounterpartyContacts()
      this.counterpartyContacts = this.data.uuid_ref_sender;
      this.delivery = {
        uuid: this.data.uuid,
        city: this.data.uuid_ref_city,
        city_name: '',
        name: this.data.name,
        warehouse: this.data.uuid_ref_warehouse,
        warehouse_name: '',
      }
      await this.fetchWarehouseByRef(this.delivery.warehouse);
      this.updateNameAddress
    },
    resetForm() {
      this.$v.$reset();
      this.counterparty = null;
      this.counterpartyContacts = null;
      this.delivery = {
        uuid: '',
        city: '',
        city_name: '',
        name: '',
        warehouse: '',
        warehouse_name: '',
      }
    },
  },
  computed: {
    visibility: {
      get() {
        if(Object.keys(this.data).length) {
          this.pasteForm()
        }
        return this.visible;
      },
      set() {
        this.resetForm();
        this.$emit('close');
      },
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.delivery.city.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.delivery.city.required
      && errors.push(this.$t('form.errors.CityRequired'));
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.delivery.name.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.delivery.name.required
      && errors.push(this.$t('form.errors.NameRequired'));
      return errors;
    },
    counterpartyContactsErrors() {
      const errors = [];
      if (!this.$v.counterpartyContacts.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.counterpartyContacts.required
      && errors.push(this.$t('form.errors.CityRequired'));
      return errors;
    },
    counterpartyErrors() {
      const errors = [];
      if (!this.$v.counterparty.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.counterparty.required
      && errors.push(this.$t('form.errors.CounterpartyRequired'));
      return errors;
    },
    warehouseErrors() {
      const errors = [];
      if (!this.$v.delivery.warehouse.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.delivery.warehouse.required
      && errors.push(this.$t('form.errors.DepartmentRequired'));
      return errors;
    },
  },
  validations: {
    counterparty: { required },
    counterpartyContacts: { required },
    delivery: {
      warehouse: { required },
      city: { required },
      name: { required },
    },
  },
};
</script>

<style scoped lang="scss">
.npDialog{
  &__header{
    &-title{
      padding: 32px 24px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: #3D3D3D;
    }
    &-nav{
      display: flex;
      padding: 12px 24px;
    }
  }
}
</style>
