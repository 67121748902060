<template>
  <VCol cols="12" class="text-center py-0" style="max-width:350px">
    <VRow v-if="!user.image && !photoPreview" class="d-flex justify-content-center align-center">
      <VCol cols="auto">
        <VIcon size="70">mdi-account-circle</VIcon>
      </VCol>
      <VCol cols="auto">
        <label for="customer_photo">
          <VBtn color="success" @click="getFile">{{$t('profile.changeBtn')}}</VBtn>
        </label>
      </VCol>
    </VRow>

    <VRow
      v-else-if="user.image && !photoPreview"
      class="d-flex justify-content-center align-center"
    >
      <VCol cols="auto" class="d-flex justify-content-center align-items-center">
        <img :src="user.image" alt="" width="80" class="img-preview" />
      </VCol>
      <VCol cols="auto">
        <label for="customer_photo">
          <VBtn color="success" @click="getFile">{{$t('profile.changeBtn')}}</VBtn>
        </label>
      </VCol>
    </VRow>
    <VFileInput
      id="customer_photo"
      class="d-none"
      v-model="user.photo"
      accept="image/*"
      @change="makePreview"
    />
    <VRow class="d-flex justify-content-center align-center" v-if="photoPreview">
      <VCol cols="2" class="d-flex justify-content-center align-items-center">
        <img :src="photoPreview" alt="" width="80" class="img-preview" />
      </VCol>
      <VCol cols="6" v-if="!pre">
        <VBtn
          @click="
            user.photo = null;
            photoPreview = null;
            $emit('changed',false)

          "
          depressed
        >
          {{$t('btn.delete')}}
        </VBtn>
      </VCol>
      <VCol cols="6" v-if="pre">
        <VBtn color="success" @click="getFile">{{$t('profile.changeBtn')}}</VBtn>
      </VCol>
    </VRow>
  </VCol>
</template>

<script>
import { mapActions } from "vuex";
import * as actionTypes from "../../store/modules/auth/types/actions";

export default {
  name: "ProfileAvatar",
  data: () => ({
    photoPreview: false,
    pre: false
  }),
  props: {
    user: {
      require: true
    },
    changePhotoPreview: {
      require: false
    }
  },
  watch: {
    changePhotoPreview: {
      deep: true,
      handler() {
        this.pre = true;
      }
    }
  },
  methods: {
    getFile() {
      const fileUpload = document.getElementById("customer_photo");
      if (fileUpload != null) {
        fileUpload.click();
      }
    },
    ...mapActions("auth", {
      updateUserData: actionTypes.USER_UPDATE_DATA
    }),

    makePreview() {
      let photoPreview = null;
      const { photo } = this.user;
      const reader = new FileReader();
      reader.readAsDataURL(photo);
      reader.onloadend = ev => {
        photoPreview = ev.target.result;
        this.photoPreview = photoPreview;
              this.pre = false;
      };

    }
  }
};
</script>

<style scoped>
.img-preview {
  border-radius: 50%;
  border: 1px solid lightgray;
  min-width: 80px;
  width: 80px;
  height: 80px;
  object-fit: cover;
}
</style>
