<template>
  <div class="w-100 mb-5">
    <VCol cols="12" class="py-0">
      <VRow align="center" justify="space-around"  no-gutters>
        <div class="my-3 text-center font-16 text-black txt">
          <span>{{$t('form.status')}} :</span> <b>{{ $t(`auth.${user.type}`) }}</b>
        </div>
        <div class="my-3 text-center font-16 text-black txt " v-if="permissions.client">
            <span>{{$t('profile.payment_plan')}} :</span> <b>{{ userPlanInfo.name || 'Не вибрано'}}</b>
        </div>
        <div class="my-3 text-center font-16 text-black txt" v-if="permissions.client">
            <span>{{$t('profile.deport')}}:</span> <b>{{ deport.name || 'Не вибрано'}}</b>
        </div>
      </VRow>
    </VCol>
    <VCol cols="12" class="py-0 my-3 mt-3">
      <VTextField
        :label="$t('form.name')"
        dense
        color="green"
        outlined
        v-model="user.name"
        :error-messages="nameErrors"
        background-color="white"
        class="br-10"
        v-on:keypress="noEngText($event)"
        @paste="noEngTextPasted($event)"
        :hint="onEngText  ? '' : $t('form.hint.NameNoEngText')"
        @input="noEngText($event,false)"
      />
    </VCol>
    <VCol cols="12" class="py-0 my-3">
      <VTextField
        :label="$t('form.surname')"
        dense
        outlined
        color="green"
        v-model="user.surname"
        :error-messages="surnameErrors"
        background-color="white"
        @paste="noEngTextPasted($event)"
        class="br-10"
        v-on:keypress="noEngText($event)"
        :hint="onEngText  ? '' : $t('form.hint.SurnameNoEngText')"
        @input="noEngText($event,false)"
      />
    </VCol>
    <VCol cols="12" class="py-0 my-3">
      <VTextField
        class="inputPhone br-10"
        :label="$t('form.phone_number')"
        dense
        color="green"
        outlined
        prefix="+38"
        background-color="white"
        v-on:keypress="onlyNumber($event)"
        @paste="noEngTextPasted($event,true)"
        v-model="user.phone"
        :maxLength="20"
        :error-messages="phoneErrors"
      >
      </VTextField>
    </VCol>
    <VCol cols="12" class="py-0 my-3" v-if="permissions.client">
      <VTextField
        :label="$t('form.company')"
        dense
        outlined
        v-model="user.company"
        color="green"
        background-color="white"
        class="br-10"
      />
    </VCol>
    <VCol cols="12" class="py-0 my-3 br-10" v-if="permissions.client">
      <VTextField
        class="my-0 py-0 d-flex align-items-center br-10"
        dense
        :disabled="true"
        outlined
        label="CRM Api Key"
        v-model="user.uuid_crm_apikey"
        color="green"
        left
        icon="false"
        background-color="white"
        id="custom-disabled"
      >
        <template slot="append" v-if="user.uuid_crm_apikey">
          <VBtn icon class="mb-1 py-0">
            <v-icon @click="copyToCLipboard" class="icon-btn mb-0 py-0">mdi-content-copy</v-icon>
          </VBtn>
        </template>
      </VTextField>
    </VCol>

    <VCol cols="12" class="pb-5 " v-if="permissions.client">
      <div class="d-flex align-center">
        <VTextField
          dense
          outlined
          aria-autocomplete="off"
          v-model="user.np_apikey"
          label="NP Api Key"
          color="green"
          background-color="white"
          class="br-10"
          hide-details=""
          :disabled="!user.is_np_apikey"
        />
        <v-switch :false-value="0" :true-value="1" color="success" hide-details v-model="user.is_np_apikey"/>
      </div>
      <div class="listCounterparty" v-if="user.is_np_apikey">
        <v-radio-group v-model="listCounterpartyDefaultItem" hide-details="">

          <div class="listCounterparty__item" v-for="(item, index) in listCounterparty"
               :key="index">
            <span>
              {{ item.name }}
            </span>
            <div class="listCounterparty__item-buttons">
              <v-radio
                :key="index"
                :value="index"
                class="mb-0"

                @change="changeRadioCounterparty(item.uuid)"
              ></v-radio>
              <v-icon @click="deleteCounterparty(item)">mdi-trash-can-outline</v-icon>
              <v-icon @click="editCounterparty(item)">mdi-pencil</v-icon>
            </div>
          </div>
          <div class="listCounterparty__item pt-2">
            <VBtn @click="npDialog.visible = true" class="success-bg text-white br-10 w-100">
              {{$t('btn.add')}}
            </VBtn>
          </div>
        </v-radio-group>
      </div>
    </VCol>

    <VCol cols="12" class="pb-5 " v-if="permissions.can_see_sms_integrations">
      <div class="d-flex align-center">
        <VTextField
          dense
          outlined
          aria-autocomplete="off"
          v-model="user.turbosms_apikey"
          label="Turbo SMS Api Key"
          color="green"
          background-color="white"
          class="br-10"
          hide-details=""
          :disabled="!user.is_turbosms_active"
        />
        <v-switch :false-value="0" :true-value="1" color="success" hide-details v-model="user.is_turbosms_active"/>
      </div>
      <div class="listCounterparty pa-2" v-if="user.is_turbosms_active">
        <VTextField
          dense
          outlined
          aria-autocomplete="off"
          v-model="user.turbosms_sender"
          label="Sender Name"
          color="green"
          background-color="white"
          class="br-10"
          hide-details=""
        />
      </div>
    </VCol>

    <!-- <VCol cols="12" class="pt-5 ">
      <div class="d-flex align-center">
        <VTextField
          dense
          outlined
          aria-autocomplete="off"
          v-model="user.sms_token"
          label="SMS Key"
          color="green"
          background-color="white"
          class="br-10"
          hide-details=""
          :disabled="!is_ts_token"
        />
        <v-switch color="success" hide-details v-model="is_ts_token"/>
      </div>
      <div class="listCounterparty" v-if="is_ts_token">

          <div class="listCounterparty__item" v-for="(item, index) in SMSlayoutList"
               :key="index">
            <span>
              {{ item.name }}
            </span>
            <div class="listCounterparty__item-buttons">
              <v-icon @click="onDeletSMS(item.uuid)">mdi-trash-can-outline</v-icon>
              <v-icon @click="onEditSMS(item)">mdi-pencil</v-icon>
            </div>
          </div>
          <div v-if="SMSlayoutList.length === 0" class="text-center my-4">
            У вас ще немає шаблонів
          </div>
          <div class="listCounterparty__item pt-2" >
            <VBtn @click="visibleLayout = true" class="success-bg text-white br-10 w-100">
              Додати
            </VBtn>
          </div>

      </div>

    </VCol> -->

    <VCol cols="12" class="px-0 py-5 mt-5" style="padding:0 11px!important" v-if="!showPass">
      <VBtn class="py-5 br-10" color="grey darken-1" outlined block @click="showPass = true">
        <v-icon left> mdi-lock</v-icon>
       {{$t('profile.change_pass')}}
      </VBtn>
    </VCol>
    <VCol cols="12" class="px-0 py-5 mt-5" style="padding:0 11px!important">
      <div class="change-pass" :class="{'sm' : $vuetify.breakpoint.smAndDown}" v-if="showPass">
        <VRow no-gutters>
          <VCol cols="auto" class="d-flex align-items-center">
            <VBtn icon @click="showPass = false">
              <v-icon>
                mdi-close
              </v-icon>
            </VBtn>
          </VCol>
          <div class="d-flex align-center">
            <span> {{$t('profile.change_pass')}}</span>
          </div>
        </VRow>
        <VCol></VCol>
        <form>

          <VTextField
            outlined
            class="iconPass br-10"
            :label="$t('profile.current_pass')"
            background-color="white"
            dense
            color="green"
            v-model="passForm.currentPass"
            :type="currentPasswordVisible ? 'text' : 'password'"
            :append-icon="currentPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="currentPasswordVisible = !currentPasswordVisible"
            :error-messages="passwordErrors"
          />
          <VTextField
            outlined
            class="iconPass br-10"
            :label="$t('profile.new_pass')"
            background-color="white"
            dense
            color="green"
            v-model="passForm.newPass"
            :type="newPasswordVisible ? 'text' : 'password'"
            :append-icon="newPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="newPasswordVisible = !newPasswordVisible"
            :error-messages="newPassErrors"
          />
          <VTextField
            outlined
            class="iconPass br-10"
            :label="$t('profile.сonfirm_pass')"
            background-color="white"
            dense
            color="green"
            v-model="passForm.newPassRepeat"
            :type="repeatPasswordVisible ? 'text' : 'password'"
            :append-icon="repeatPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="repeatPasswordVisible = !repeatPasswordVisible"
            :error-messages="newPassRepeatErrors "
          />
        </form>
      </div>
    </VCol>
    <VCol cols="12" class="px-0 py-5 mt-5" style="padding:0 11px!important">
      <VBtn
        block
        color="success"
        class="py-5 br-10 mb-15 mb-sm-0"
        id="custom-disabled"
        @click="IsOpenPassForm"
        :disabled="(!passwordChanged && !userChanged && !counterpartyChanged)"
      >
        {{$t('btn.save')}}
      </VBtn>
    </VCol>
    <ProfileDialogNP
      :visible="npDialog.visible"
      :typeDialog="npDialog.type"
      :data="npDialog.data"
      @close="closeProfileDialogNP"
    ></ProfileDialogNP>
    <ConfirmDeleteDialog
      :visible="deleteConfirm.visible"
      @close="closeDeleteCounterpartyDialog"
      @delete="onDelete"
    />
    <!-- <AddNewLayout :visible="visibleLayout" @close="visibleLayout = false"/> -->
  </div>
</template>

<script>
import _ from 'lodash';
import {required, sameAs} from 'vuelidate/lib/validators';
import {validationMixin} from 'vuelidate';
import { mapGetters, mapActions } from "vuex";
import user from '../../mixins/user';
import * as actionTypes from '../../store/modules/auth/types/actions';
import phoneMask from '../../validators/phoneMask';
import usersService from '../../services/request/users/usersService';
import notifications from '../../mixins/notifications';
import loader from '../../mixins/loader';
import language from '../../mixins/language';
import ProfileDialogNP from './ProfileDialogNP.vue';
import ConfirmDeleteDialog from '../dialog/ConfirmDeleteDialog.vue';
import novaPochtaService from '../../services/request/novaPochta/novaPochtaService';
import EventBus from '../../events/EventBus';
// import AddNewLayout from '../sms/AddNewLayout.vue';
import paymentPlansService from "@/services/request/payment-plans/paymentPlansService";
import passwordMask from '../../validators/passwordMask';
import {noEngText, onlyNumber, noEngTextPasted} from "../../mixins/helpers";
import noEngTextValidator from "@/validators/noEngText";
import * as deportGetterTypes from "@/store/modules/moveList/types/getters";
import * as deportActionTypes from "@/store/modules/moveList/types/actions";


// const validPhone = (value) => value.replace(/[^+\d]/g, '').length === 13;

export default {
  mixins: [notifications, loader, validationMixin, user, language],
  props: {
    user: {
      require: true,
    },
    inputUser: {
      require: true,
    },
    inputUserReset: {
      require: true,
    },
    changedDis: {
      require: true,
    },
  },
  components: {
    ProfileDialogNP,
    ConfirmDeleteDialog,
  },
  validations: {
    user: {
      name: {required, noEngTextValidator},
      surname: {required, noEngTextValidator},
      phone: {required, phoneMask},
    },
    passForm: {
      newPass: {required, passwordMask},
      newPassRepeat: {required, passwordMask, sameAsPassword: sameAs('newPass')},
      currentPass: {required},

    },
  },
  data: () => ({
    onEngText:true,
    SMSlayoutList:[],
    is_ts_token:false,
    visibleLayout:false,
    listCounterparty: [],
    listCounterpartyDefaultItem: 1,
    originalListCounterpartyDefaultItem: 1,
    npDialog: {
      visible: false,
      type: 'add',
      data: {}
    },
    deleteConfirm: {
      visible: false,
      data: {}
    },
    userChanged: false,
    passwordChanged: false,
    counterpartyChanged: false,
    showPass: false,
    currentPasswordVisible: false,
    newPasswordVisible: false,
    repeatPasswordVisible: false,
    fixedUser: {},
    passForm: {
      newPass: '',
      newPassRepeat: '',
      currentPass: '',
    },
    userPlanInfo:{},
    deport: {}
  }),
  watch: {
    user: {
      deep: true,
      handler() {
        this.fixedUser = {...this.user};
        this.fixedUser.phone = this.fixedUser.phone.replace(/[^+\d]/g, '');
        this.userChanged = !_.isEqual(this.fixedUser, this.inputUser);
        // // orderDialog('changed', this.changed);
      },
    },
    showPass:{
      deep: true,
      handler() {
        if( !this.showPass ) {
          this.passwordChanged = false;
        }
      },
    },
    passForm: {
      deep: true,
      handler() {
        this.passwordChanged = this.showPass && !_.isEqual(this.passForm, { newPass: '', newPassRepeat: '', currentPass: ''});
        // // orderDialog('changed', this.changed);
        // // orderDialog('input', this.inputUserReset);
        // orderDialog(this.reset);
      },
    },
    listCounterpartyDefaultItem: {
      deep: true,
      handler() {
        this.counterpartyChanged = !_.isEqual(this.listCounterpartyDefaultItem, this.originalListCounterpartyDefaultItem);
        // // orderDialog('changed', this.changed);
      },
    },
  },
  computed: {
    ...mapGetters("moveList", {
      deports: deportGetterTypes.GET_DEPORTS
    }),
    passwordErrors() {
      const errors = [];
      if (!this.$v.passForm.currentPass.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.passForm.currentPass.required
      && errors.push(this.$t('form.errors.CurrentPassRequired'));
      return errors;
    },
    newPassErrors() {
      const errors = [];
      if (!this.$v.passForm.newPass.$dirty) {
        return errors;
      }
      if (!this.$v.passForm.newPass.passwordMask) {
        errors.push(this.$t('form.errors.PasswordMustBeLike'));

        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.passForm.newPass.required && errors.push(this.$t('form.errors.NewPassRequired'));
      return errors;
    },
    newPassRepeatErrors() {
      const errors = [];
      if (!this.$v.passForm.newPassRepeat.$dirty) {
        return errors;
      }
      if (!this.$v.passForm.newPassRepeat.required) {
        errors.push(this.$t('form.errors.ConfirmPassRequired'));

        return errors;
      }
      if (!this.$v.passForm.newPassRepeat.passwordMask) {
        errors.push(this.$t('form.errors.PasswordMustBeLike'));

        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.passForm.newPassRepeat.sameAsPassword
      && errors.push(this.$t('form.errors.PasswordsDontMatch'));
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.user.name.$dirty) {
        return errors;
      }
      if (!this.$v.user.name.noEngTextValidator) {
        errors.push(this.$t("form.hint.NameNoEngText"));
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.name.required && errors.push(this.$t('form.errors.NameRequired'));
      return errors;
    },
    surnameErrors() {
      const errors = [];
      if (!this.$v.user.surname.$dirty) {
        return errors;
      }
      if (!this.$v.user.surname.noEngTextValidator) {
        errors.push(this.$t("form.hint.SurnameNoEngText"));
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.surname.required && errors.push(this.$t('form.errors.SurnameRequired'));
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.user.phone.$dirty) {
        return errors;
      }
      if (!this.$v.user.phone.required) {
        errors.push(this.$t('form.errors.PhoneNumberRequired'));
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.user.phone.phoneMask && errors.push(this.$t('form.errors.PhoneNumberMustBeLike'));
      return errors;
    },
    emailErrors() {
      return [];
    },
  },
  async mounted() {    
    EventBus.$on('reload-list-counterparty', () => {
      this.counterpartyList();
    });
    this.counterpartyList();
    if(this.permissions.client){
      await this.fetchDeports()
      this.getPaymentPlan();
      this.getDeport();
    }
  },
  methods: {
  noEngTextPasted,
  noEngText,
  onlyNumber,
  ...mapActions("moveList", {
      fetchDeports: deportActionTypes.FETCH_DEPORTS
    }),
    async getPaymentPlan() {
      try {
        if(this.getUser.uuid_user_plan){
          const planItem = await paymentPlansService.usersPlansInfo(this.getUser.uuid_user_plan);
          if( !planItem ){
            const notSetPlan = {
              name:this.$t('form.not_selected'),
              uuid: '00000000-0000-0000-0000-000000000000'
            }
          this.userPlanInfo = notSetPlan

          } else {
            this.userPlanInfo = planItem
          }
        }
      } catch (e) {
          this.setErrorNotification(this.$t('paymentPlan.withoutPaymentPlan'));
      }
    },
    ...mapActions('auth', {
      updateUserData: actionTypes.USER_UPDATE_DATA,
      logIn: actionTypes.LOG_IN,
    }),
    getDeport() {
      this.deport = this.deports.find((dep) => {
        return dep.uuid === this.user.uuid_deport
      })
    },
    async changeRadioCounterparty(uuid) {
      await novaPochtaService.setDefaultCounterparty(uuid);
    },
    IsOpenPassForm() {
      console.log(this.userChanged);
      if (!this.showPass) {
        this.onUpdate();
      } else if (this.showPass || this.changed) {
        this.ChangePass();

        this.onUpdate();
      } else {
        this.ChangePass();
      }
    },
    async copyToCLipboard() {
      try {
        await navigator.clipboard.writeText(this.user.uuid_crm_apikey);
        this.setSuccessNotification(this.$t('profile.copied_to_clipboard'));
      } catch (e) {
        this.setErrorNotification(e);
      }
    },
    async ChangePass() {
      this.$v.$touch();
      if (!this.$v.passForm.$invalid) {
        try {
          // orderDialog('this.showPass', this.showPass);
          if (this.showPass) {
            const formDataPass = new FormData();
            formDataPass.append('email', this.user.email);
            formDataPass.append('password_new', this.passForm.newPass);
            formDataPass.append('password_current', this.passForm.currentPass);
            const response = await usersService.userPasswordChange(formDataPass);
            // orderDialog('pass-respons', response);
            this.passForm = {};
            this.$v.$reset();
            this.setSuccessNotification(this.$t('profile.password_changed_successfully'));
          }
        } catch (e) {
          this.setErrorNotification(this.$t(`auth.errors.${e.error.description}`));
          
        }
      }
    },
    async onUpdate() {
      this.$v.$touch();
      if (!this.$v.user.$invalid && !this.showPass) {
        try {
          this.setLoading(true);
          const formData = new FormData();
          // orderDialog(this.getUser);
          formData.append('uuid', this.getUser.uuid || '');

          const changedData = this.$getChangedData(this.user, this.inputUser);
          changedData.forEach(data => {
            formData.append(data.field, data.value);
          });
          // formData.append('name', this.user.name || '');
          // formData.append('surname', this.user.surname || '');
          // formData.append('phone', `+38${this.user.phone}`);
          // formData.append('company', this.user.company || '');
          // if (this.user.photo) {
          //   formData.append('image', this.user.photo);
          // }
          // if (this.user.np_apikey) {
          //   formData.append('np_apikey', this.user.np_apikey);
          // }
          // if (this.user.np_apikey) {
          //   formData.append('np_apikey', this.user.np_apikey);
          // }
          // formData.append('is_turbosms_active', this.user.is_turbosms_active ? '1' : '0');
          // if (this.user.is_turbosms_active) {
          //   formData.append('turbosms_apikey', this.user.turbosms_apikey);
          //   formData.append('turbosms_sender', this.user.turbosms_sender);
          // }
          // formData.append('is_np_apikey', this.user.is_np_apikey ? '1' : '0');
          await this.updateUserData(formData);
          this.userChanged = false;
          this.passwordChanged = false;
          this.counterpartyChanged = false;
          this.setSuccessNotification(this.$t('profile.successfully_updated'));
          this.$v.$reset();
          this.setLoading(false);
          this.$emit('changeInputUser', true);
          this.$emit('photoPreview', true);
          // orderDialog(this.user.path_img, this.inputUser.path_img);
        } catch (e) {
          this.setLoading(false);
          this.setErrorNotification(e);
        }
      }
    },
    onDelete(){
      this.delayCounterparty(this.deleteConfirm.data.uuid);
      this.closeDeleteCounterpartyDialog();
    },
    closeDeleteCounterpartyDialog() {
      this.deleteConfirm = {
        visible: false,
        data: {}
      }
    },
    deleteCounterparty(item) {
      this.deleteConfirm = {
        visible: true,
        data: item
      }
    },
    closeProfileDialogNP() {
      this.npDialog = {
        visible: false,
        type: 'add',
        data: {}
      }
    },
    editCounterparty(item) {
      this.npDialog = {
        visible: true,
        type: 'edit',
        data: item
      }
    },
    async delayCounterparty(uuid) {
      try {
        await novaPochtaService.daleyCounterpartyItem(uuid);
        this.setSuccessNotification('Успішно видалено');
        this.counterpartyList()
      } catch (e) {
        console.error(e)
      }
    },
    async counterpartyList() {
      try {
        const response = await novaPochtaService.getCounterpartyList();
        // // orderDialog('counterpartyList', response);
        this.listCounterparty = response;
        for (let i = 0; i < response.length; i++){
          if (Number(response[i].is_default) === 1) {
            this.listCounterpartyDefaultItem = i;
            this.originalListCounterpartyDefaultItem = i;
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.listCounterparty {
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.38);
  margin: 15px 0 25px;

  &__item {
    padding: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.38);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-buttons {
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    &:last-child {
      border: 0;
    }
  }
}

.icon-btn {
  pointer-events: auto;
}

#custom-disabled.v-btn--disabled {
  opacity: 0.5;
  background: #4fae3b !important;
  color: #ffff !important;
}

.txt {
  span,
  b {
    font-weight: normal;
    font-size: 16px;
    color: #676767;
  }

  b {
    font-weight: 500;
    color: #3d3d3d;
  }
}

.change-pass {
  border-radius: 10px;
  padding: 14px 56px;
  border: 1px solid #dcdcdc;
  position: relative;

  span {
    position: absolute;
    text-align: center;
    left: 0;
    width: 100%;

    pointer-events: none;
  }

  &.sm {
    padding: 14px 16px;

  }
}

.btn-addCounterparties {
  min-width: 42px !important;
  padding: 0 !important;
}
</style>
